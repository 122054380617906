import * as React from 'react';
import { Container } from '@mui/material';

export const Layout = (props) => {
  const { children } = props;

  return (
    <Container maxWidth="lg" sx={{ background: 'transparent' }}>
      {children}
    </Container>
  );
};
