import React from 'react';
import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { LinearProgressWithLabel } from '../../google-drive-picker';
import { DeleteOutlined } from '@mui/icons-material';

const getFileLink = async (id, username) => {
  const accessResponse = await fetch('/access', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: 'Bearer ' + localStorage.getItem('token') || ''
    }
  });
  const { token } = await accessResponse.json();

  const lastPromise = new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open(
      'GET',
      `https://www.googleapis.com/drive/v3/files/${id}?alt=media&key=AIzaSyBdRz4XQovj0mi6wRF_GMZp5Ccd9eO_q8s`
    );
    xhr.onload = (event) => {
      resolve(event.currentTarget.response);
    };
    xhr.responseType = 'blob';
    xhr.setRequestHeader('authorization', 'Bearer ' + token);
    xhr.send();
  });

  await lastPromise
    .then((blob) => {
      const href = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', `${username}.dem`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const UsersTable = (props) => {
  const { users, handleUserSelect, selectedUserId, onDelete } = props;
  const [progress, setProgress] = React.useState(0);

  const handleDownload = React.useCallback(
    (userId, username) => async (event) => {
      event.preventDefault();
      event.stopPropagation();
      setProgress(100);

      try {
        await getFileLink(userId, username);
      } catch (err) {
        console.log(err);
      } finally {
        setProgress(0);
      }
    },
    []
  );

  const handleDeleteFile = React.useCallback(
    async (event, args) => {
      event.preventDefault();
      event.stopPropagation();

      const { fileId } = args;

      try {
        await fetch(`/delete?fileId=${fileId}`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });

        await onDelete?.();
      } catch (err) {
        console.error(err);
      }
    },
    [onDelete]
  );

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650, fontSize: '1.15rem' }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Username</TableCell>
              <TableCell align="center">Demo file</TableCell>
              <TableCell align="center">Main Problem</TableCell>
              <TableCell align="center">Our analysis</TableCell>
              <TableCell align="center">Questions</TableCell>
              <TableCell align="center">Our answers</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((row) => (
              <TableRow
                key={row.id}
                sx={{
                  '&:last-child td, &:last-child th': {
                    border: 0
                  },
                  cursor: 'pointer',
                  '&:hover': {
                    bgcolor: '#f5f5f5'
                  },
                  bgcolor: selectedUserId === row.id ? 'primary.light' : null
                }}
                onClick={() => handleUserSelect(row.id)}>
                <TableCell component="th" scope="row" sx={{ fontWeight: 600 }}>
                  {row.username}
                </TableCell>
                <TableCell align="center">
                  {!row.data?.fileId ? null : (
                    <Button
                      disabled={progress > 0}
                      sx={{
                        textDecoration: 'underline',
                        '&:hover': {
                          textDecoration: 'underline'
                        }
                      }}
                      onClick={handleDownload(
                        row.data?.fileId,
                        row.username
                      )}>{`${row.username}.dem`}</Button>
                  )}
                </TableCell>
                <TableCell align="center">
                  {Boolean(row.data?.question) ? <CheckCircleIcon color="success" /> : null}
                </TableCell>
                <TableCell align="center">
                  {Boolean(row.data?.answer) ? <CheckCircleIcon color="success" /> : null}
                </TableCell>
                <TableCell align="center">
                  {Boolean(row.data?.second_question) ? <CheckCircleIcon color="success" /> : null}
                </TableCell>
                <TableCell align="center">
                  {Boolean(row.data?.second_answer) ? <CheckCircleIcon color="success" /> : null}
                </TableCell>
                <TableCell align="center">
                  <Tooltip title="delete user file">
                    <IconButton
                      disabled={!row.data?.fileId}
                      color="error"
                      onClick={(e) => handleDeleteFile(e, { fileId: row.data.fileId })}>
                      <DeleteOutlined />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ width: '100%' }}>
        {progress === 0 ? null : <LinearProgressWithLabel variant={undefined} />}
      </Box>
    </>
  );
};
