import React from 'react';
import Linkify from 'react-linkify';
import { Link } from '@mui/material';

export const WithLinks = ({ children }) => {
  return (
    <Linkify
      componentDecorator={(decoratedHref, decoratedText, key) => (
        <Link target="blank" href={decoratedHref} key={key}>
          {decoratedText}
        </Link>
      )}>
      {children}
    </Linkify>
  );
};
