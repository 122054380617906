import * as React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { StyledTextarea } from '../textarea';
import { LoadingButton } from '@mui/lab';
import { TextareaFilled } from '../textarea';
const MAX_SYMBOLS_COUNT = 500;

export const MessageForm = (props) => {
  const {
    pending,
    label,
    buttonText,
    disabled,
    readyContent,
    onSubmit,
    maxSymbols = MAX_SYMBOLS_COUNT,
    placeholder = 'Describe the main issue'
  } = props;

  const [content, setContent] = React.useState('');
  const [error, setError] = React.useState(false);

  const handleSubmit = React.useCallback(
    (event) => {
      event.preventDefault();

      onSubmit(content);
    },
    [content, onSubmit]
  );

  const handleChange = React.useCallback(
    (event) => {
      const value = event.target.value;

      if (value.length > maxSymbols) {
        setError(true);
      } else {
        setError(false);
      }

      setContent(event.target.value);
    },
    [maxSymbols, setContent, setError]
  );

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{ mb: 4, width: '100%' }}
      onSubmit={handleSubmit}>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        {!label ? null : (
          <Grid item>
            <Typography sx={{ textWrap: 'balance', textAlign: 'justify' }}>{label}</Typography>
          </Grid>
        )}

        <Grid item xs={12}>
          {readyContent ? (
            <TextareaFilled>{readyContent}</TextareaFilled>
          ) : (
            <StyledTextarea
              onChange={handleChange}
              aria-label="you question"
              minRows={3}
              placeholder={placeholder}
              disabled={disabled || pending}
              value={content ?? ''}
            />
          )}
        </Grid>

        {!error ? null : (
          <Grid item xs={12}>
            <Typography color="error" sx={{ textAlign: 'center' }}>
              Max length {maxSymbols} symbols
            </Typography>
          </Grid>
        )}

        <Grid item>
          <LoadingButton
            disabled={error || disabled}
            loading={pending}
            type="submit"
            size="large"
            sx={{ fontSize: '1.15rem' }}
            variant="contained">
            {buttonText}
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
};
