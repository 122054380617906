import * as React from 'react';
import { Box, Typography, Grid, TextField } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';

const TextFieldStyled = styled(TextField)({
  fontSize: '1.15rem',
  '& label': { fontSize: '1.15rem' },
  '& fieldset': { fontSize: '1.15rem' },
  '& input': { fontSize: '1.15rem' }
});

export const LoginForm = () => {
  const navigate = useNavigate();
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [pending, setPending] = React.useState(false);
  const [error, setError] = React.useState(false);

  const onSubmit = React.useCallback(
    async (event) => {
      event.preventDefault();
      setPending(true);
      setError(false);

      try {
        const response = await fetch('/login', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ username, password })
        });

        const { token } = await response.json();

        if (token) {
          localStorage.setItem('token', token);
          navigate('/user');
        }
      } catch (error) {
        console.error(error);
        setError(true);
      } finally {
        setPending(false);
      }
    },
    [navigate, username, password]
  );

  return (
    <Box sx={{ height: '100vh', display: 'flex', alignItems: 'center' }}>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        sx={{
          m: '0 auto',
          p: 4,
          width: 'fit-content',
          background: 'rgb(240, 240, 255)',
          boxShadow: '0 0 35px 35px rgb(240, 240, 255)'
        }}
        onSubmit={onSubmit}>
        <Grid
          container
          spacing={2}
          sx={{
            '& > div': {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }
          }}>
          <Grid item xs={12}>
            <TextFieldStyled
              name="username"
              label="Username"
              value={username}
              placeholder="Enter username"
              onChange={(event) => setUsername(event.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextFieldStyled
              name="password"
              label="Password"
              type="password"
              value={password}
              placeholder="Enter password"
              onChange={(event) => setPassword(event.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <LoadingButton
              loading={pending}
              type="submit"
              variant="contained"
              size="large"
              sx={{ fontSize: '1.15rem' }}>
              Login
            </LoadingButton>
          </Grid>

          <Grid item xs={12}>
            {!error ? null : <Typography color="error">Wrong credentials</Typography>}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
