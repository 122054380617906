import * as React from 'react';
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { ClientSide } from '../client-side';
import { AnalystSide } from '../analyst-side';
import { useNavigate } from 'react-router-dom';

const fetchMe = async () => {
  try {
    const response = await fetch('/me', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: 'Bearer ' + localStorage.getItem('token') || ''
      }
    });

    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

export const Dashboard = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const [user, setUser] = React.useState(null);

  const isAnalyst = user?.role === 'analyst';
  const userName = user?.name;

  const handleFetchMe = React.useCallback(async () => {
    try {
      const user = await fetchMe();
      setUser(user);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }, [setUser, setLoading]);

  const handleExit = React.useCallback(() => {
    localStorage.removeItem('token');
    navigate('/login');
  }, [navigate]);

  React.useEffect(() => {
    handleFetchMe();
  }, [handleFetchMe]);

  if (loading) {
    return (
      <Box sx={{ mt: 6 }}>
        <Grid container sx={{ mb: 2 }} justifyContent="center">
          <Grid item xs={12}>
            <Typography sx={{ textAlign: 'center', mb: 2 }}>Loading...</Typography>
          </Grid>

          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      </Box>
    );
  }

  if (!loading && !user) {
    localStorage.removeItem('token');
    navigate('/login');
  }

  return (
    <Box sx={{ mt: 6 }}>
      <Grid container sx={{ mb: 2 }}>
        <Grid item xs={3} />

        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography variant="h4" sx={{ textAlign: 'center' }}>
            {userName}
          </Typography>
        </Grid>

        <Grid item xs={3} align="right">
          <Button onClick={handleExit} size="large" sx={{ fontSize: '1.15rem' }}>
            Exit
          </Button>
        </Grid>
      </Grid>

      {!isAnalyst ? (
        <ClientSide user={user} fetchUser={handleFetchMe} />
      ) : (
        <AnalystSide user={user} />
      )}
    </Box>
  );
};
