import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { GoogleDrivePicker } from '../google-drive-picker';
import { MessageForm } from '../message-form';
import { WithLinks } from '../linkify';

async function fetchSetQuestion(payload) {
  const { content, type } = payload;

  await fetch('/analyze', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: 'Bearer ' + localStorage.getItem('token') || ''
    },
    body: JSON.stringify({ content, type })
  });
}

export const ClientSide = (props) => {
  const { user, fetchUser } = props;

  const userQuestion = user?.data?.question;
  const userAnswer = user?.data?.answer;
  const hasAnswer = Boolean(userAnswer);
  const hasQuestion = Boolean(userQuestion);
  const hasFile = Boolean(user?.data?.fileId);

  const userSecondQuestion = user?.data?.second_question;
  const userSecondAnswer = user?.data?.second_answer;
  const hasSecondAnswer = Boolean(userSecondAnswer);
  const hasSecondQuestion = Boolean(userSecondQuestion);

  const [pending, setPending] = React.useState(false);
  const [error, setError] = React.useState(false);

  const onQuestionSubmit = React.useCallback(
    async (content, type = 'question') => {
      if (error) {
        return;
      }

      setPending(true);

      try {
        await fetchSetQuestion({ content, type });
        await fetchUser();
      } catch (error) {
        console.error(error);
        setError(true);
      } finally {
        setPending(false);
      }
    },
    [error, fetchUser]
  );

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      sx={{
        m: '0 auto',
        p: 1,
        maxWidth: 640,
        pb: 10,
        background: 'rgb(240, 240, 255)',
        boxShadow: '0 0 35px 35px rgb(240, 240, 255)',
        width: '100%',
        '& > .MuiGrid-item': {
          mb: 4
        }
      }}>
      <Grid item xs={12}>
        <Typography sx={{ textWrap: 'balance', textAlign: 'justify' }}>
          Welcome to our AI-powered analytical platform, designed to be your personal coach and
          answer any questions you may have about your gameplay. Our mission is to bridge the gap
          between raw data and general tips on how to play better, while also offering you the
          opportunity to seek clarification on anything you don't understand in your favorite game,
          anytime you're available.
          <br />
          <br />
          As part of our test access, you can upload one CS GO demo file and add a comment with your
          main question or the main issue you believe you have. Our AI will then analyze the file
          and provide you with intelligent advice on where and how to improve. If you need further
          clarification on the advice given, feel free to ask more specific questions for a detailed
          answer.
          <br />
          <br />
          Let's get started! Simply click the button below to choose a demo file from your computer
          and then add your main question or describe the main issue you're facing in the demo.
        </Typography>
      </Grid>

      <Grid item xs={12}>
        {!hasFile ? (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <GoogleDrivePicker userId={user?.id} onUpload={() => fetchUser()} />
          </Box>
        ) : (
          <Typography color="success.main" sx={{ textWrap: 'balance', textAlign: 'center' }}>
            Your demo file has been uploaded!
          </Typography>
        )}
      </Grid>

      {!hasFile ? null : (
        <MessageForm
          label="Describe the main issue you're facing in the demo and push Analyze."
          onSubmit={onQuestionSubmit}
          pending={pending}
          disabled={hasQuestion || hasAnswer || !hasFile}
          buttonText={hasQuestion ? 'Sent' : 'Analyze'}
          readyContent={hasQuestion ? userQuestion : undefined}
          maxSymbols={1500}
        />
      )}

      {!hasQuestion ? null : (
        <Grid item xs={12}>
          <Typography color={hasAnswer ? 'success.main' : 'error'} sx={{ textAlign: 'center' }}>
            {hasAnswer
              ? 'Your demo file has been successfully analysed!'
              : 'Waiting for analysis to complete...'}
          </Typography>
        </Grid>
      )}

      {!hasAnswer ? null : (
        <Grid item xs={12}>
          <Typography sx={{ whiteSpace: 'pre-line', textAlign: 'justify' }}>
            <WithLinks>{userAnswer}</WithLinks>
          </Typography>
        </Grid>
      )}

      {!hasAnswer ? null : (
        <MessageForm
          onSubmit={(content) => onQuestionSubmit(content, 'second_question')}
          pending={pending}
          disabled={hasSecondQuestion || hasSecondAnswer}
          buttonText={hasSecondQuestion ? 'Sent' : 'Send'}
          placeholder="If you have any questions about given tips, you can write them here and push Send"
          readyContent={hasSecondQuestion ? userSecondQuestion : undefined}
          maxSymbols={1500}
        />
      )}

      {!hasSecondQuestion ? null : (
        <Grid item xs={12}>
          <Typography
            color={hasSecondAnswer ? 'success.main' : 'error'}
            sx={{ textAlign: 'center' }}>
            {hasSecondAnswer
              ? 'Your questions have been successfully analysed!'
              : 'Waiting for analysis to complete...'}
          </Typography>
        </Grid>
      )}

      {!hasSecondAnswer ? null : (
        <Grid item xs={12}>
          <Typography sx={{ whiteSpace: 'pre-line', textAlign: 'justify' }}>
            <WithLinks>{userSecondAnswer}</WithLinks>
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
