import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import { WithLinks } from '../linkify';

const grey = {
  50: '#f6f8fa',
  100: '#eaeef2',
  200: '#d0d7de',
  300: '#afb8c1',
  400: '#8c959f',
  500: '#6e7781',
  600: '#57606a',
  700: '#424a53',
  800: '#32383f',
  900: '#24292f'
};

const StyledTypography = styled(Typography)(
  ({ theme, disabled }) => `
    resize: none;
    width: 100%;
    font-family: IBM Plex Sans, sans-serif;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.5;
    border-radius: 12px;
    white-space: pre-line;
    padding: 12px 16px;
    color: ${grey[900]};
    background: ${'#fff'};
    border: 1px solid ${grey[200]};
    box-shadow: ${!disabled ? theme.palette.primary.light : grey[200]} 0px 2px 24px;
  
    &:hover {
      border-color: ${!disabled ? theme.palette.primary.main : 'none'};
    }
  
    &:focus {
      border-color: ${!disabled ? theme.palette.primary.main : 'none'};
      ${!disabled ? `box-shadow: 0 0 0 3px ${theme.palette.primary.light}` : ''};
    }
  
    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
);

export const TextareaFilled = ({ children }) => {
  return (
    <StyledTypography disabled multiline>
      <WithLinks>{children}</WithLinks>
    </StyledTypography>
  );
};
