import React from 'react';
import './App.css';
import { Layout } from './components/layout';
import { LoginForm } from './components/login-form';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Dashboard } from './components/dashboard';

const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem('token');

  if (!token) {
    // user is not authenticated
    return <Navigate to="/" />;
  }

  return children;
};

const LoginRoute = ({ children }) => {
  const token = localStorage.getItem('token');

  if (token) {
    return <Navigate to="/dashboard" />;
  }

  return children;
};

const AuthRoute = () => {
  const token = localStorage.getItem('token');

  if (token) {
    return <Navigate to="/dashboard" />;
  }

  return <Navigate to="/" />;
};

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '1.15rem'
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: '1.15rem'
        },
        h4: {
          fontSize: '2.5rem'
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff'
        }
      }
    }
  },
  palette: {
    background: {
      default: 'transparent'
    },
    primary: {
      main: '#5f66c0',
      light: '#bcc0f2'
    }
  }
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <CssBaseline />
        <Layout>
          <Routes>
            <Route
              path="/"
              element={
                <LoginRoute>
                  <LoginForm />
                </LoginRoute>
              }
            />

            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />

            <Route path="*" element={<AuthRoute />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
