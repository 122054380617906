import * as React from 'react';
import { Box, Button, LinearProgress, Typography } from '@mui/material';

const CHUNK_SIZE = 52428800; //50 MB;

export function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} sx={{ height: '10px' }} />
      </Box>
      {!props.value ? null : (
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      )}
    </Box>
  );
}

async function initUpload(token) {
  const response = await fetch(
    'https://www.googleapis.com/upload/drive/v3/files?uploadType=resumable',
    {
      method: 'POST',
      headers: {
        authorization: 'Bearer ' + token
      }
    }
  );

  return response.headers.get('location');
}

async function addFileIdToUser(userId, fileId) {
  await fetch('/file', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: 'Bearer ' + localStorage.getItem('token') || ''
    },
    body: JSON.stringify({ userId, fileId })
  });
}

function getFileChunks(file) {
  const chunks = [];
  let start = 0;
  let end = CHUNK_SIZE;

  while (start < file.size) {
    chunks.push(file.slice(start, end));
    start = end;
    end = start + CHUNK_SIZE;
  }

  return chunks;
}

async function uploadFileChunks(
  fileChunks,
  totalSize,
  location,
  token,
  callback,
  progressCallback,
  onError
) {
  const promises = [];

  for (let i = 0; i < fileChunks.length - 1; i++) {
    const chunk = fileChunks[i];
    const promise = new Promise((resolve, reject) => {
      const progressHandler = (e) => {
        const part = 100 / fileChunks.length;
        progressCallback(Math.round(i * part + (e.loaded / e.total) * part));
      };

      const xhr = new XMLHttpRequest();
      xhr.upload.addEventListener('progress', progressHandler, false);
      xhr.open('PUT', location);
      xhr.onload = resolve;
      xhr.onerror = () => {
        onError();
      };
      xhr.setRequestHeader('authorization', 'Bearer ' + token);
      xhr.setRequestHeader('Content-Length', chunk.size);
      xhr.setRequestHeader(
        'Content-Range',
        `bytes ${i * CHUNK_SIZE}-${i * CHUNK_SIZE + chunk.size - 1}/${totalSize}`
      );
      xhr.send(chunk);
    });

    await promise;
  }

  const lastPromise = new Promise((resolve, reject) => {
    const lastChunkIndex = fileChunks.length - 1;

    const progressHandler = (e) => {
      const part = 100 / fileChunks.length;
      progressCallback(Math.round(part * lastChunkIndex + (e.loaded / e.total) * part));
    };

    const xhr = new XMLHttpRequest();
    xhr.upload.addEventListener('progress', progressHandler, false);
    xhr.open('PUT', location);
    xhr.onload = () => {
      resolve();
      callback(xhr.response);
    };
    xhr.onerror = () => {
      onError();
    };
    xhr.setRequestHeader('authorization', 'Bearer ' + token);
    xhr.setRequestHeader('Content-Length', fileChunks[lastChunkIndex].size);
    xhr.setRequestHeader(
      'Content-Range',
      `bytes ${lastChunkIndex * CHUNK_SIZE}-${totalSize - 1}/${totalSize}`
    );
    xhr.send(fileChunks[lastChunkIndex]);
  });

  await lastPromise;
}

export const GoogleDrivePicker = (props) => {
  const { userId, onUpload } = props;

  const [error, setError] = React.useState(false);
  const [uploadStarted, setUploadStarted] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const inputRef = React.useRef(null);

  const handleInputClick = React.useCallback(
    async (file) => {
      inputRef.current?.click();
    },
    [inputRef]
  );

  const handleUpload = React.useCallback(
    async (file) => {
      if (!file) {
        return;
      }

      setError(false);

      setUploadStarted(true);
      try {
        const accessResponse = await fetch('/access', {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            authorization: 'Bearer ' + localStorage.getItem('token') || ''
          }
        });
        const { token } = await accessResponse.json();

        if (!token) {
          setError(true);
          return;
        }

        const fileChunks = getFileChunks(file);
        let location = null;

        location = await initUpload(token, file.size);
        if (!location) {
          setError(true);
          return;
        }

        await uploadFileChunks(
          fileChunks,
          file.size,
          location,
          token,
          async (response) => {
            if (response) {
              const { id } = JSON.parse(response);
              addFileIdToUser(userId, id).then(() => {
                onUpload?.();
              });

              await fetch('/upload', {
                method: 'POST',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                  authorization: 'Bearer ' + localStorage.getItem('token') || ''
                },
                body: JSON.stringify({ fileId: id, userId })
              });
            }
          },
          (progress) => setProgress(progress),
          () => {
            setError(true);
            setUploadStarted(false);
            setProgress(0);
          }
        );
      } catch (error) {
        setError(true);
        setProgress(0);
      }
    },
    [userId, setError, onUpload, setUploadStarted, setProgress]
  );

  const handleChange = React.useCallback(
    (event) => {
      if (event.target.files[0]) {
        handleUpload(event.target.files[0]);
      }
    },
    [handleUpload]
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
      {!uploadStarted ? (
        <Button
          onClick={handleInputClick}
          variant="contained"
          color="success"
          size="large"
          sx={{ textTransform: 'none', fontSize: '1.15rem' }}>
          Upload one file from computer
        </Button>
      ) : (
        <Box sx={{ width: '100%' }}>
          {error ? null : <LinearProgressWithLabel value={progress} />}
        </Box>
      )}

      {!error ? null : (
        <Box sx={{ mt: 2 }}>
          <Typography color="error">Error uploading file, try again</Typography>
        </Box>
      )}

      <input
        style={{ display: 'none' }}
        accept=".dem"
        ref={inputRef}
        type="file"
        onChange={handleChange}
      />
    </Box>
  );
};
