import React from 'react';
import { Grid, Typography } from '@mui/material';
import { UsersTable } from './users-table';
import { MessageForm } from '../message-form';
import { WithLinks } from '../linkify';

const fetchUsers = async () => {
  try {
    const response = await fetch('/users', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: 'Bearer ' + localStorage.getItem('token') || ''
      }
    });

    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const fetchSendAnswer = async (userId, type, answer) => {
  try {
    await fetch('/answer', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: 'Bearer ' + localStorage.getItem('token') || ''
      },
      body: JSON.stringify({ type, userId, content: answer })
    });
  } catch (error) {
    console.error(error);
  }
};

export const AnalystSide = () => {
  const [loading, setLoading] = React.useState(true);
  const [answerPending, setAnswerPending] = React.useState(false);
  const [users, setUsers] = React.useState(null);
  const [selectedUserId, setSelectedUserId] = React.useState(null);
  const [user, setUser] = React.useState(null);

  const userAnswer = user?.data?.answer;
  const isUserAnswered = Boolean(userAnswer);
  const userQuestion = user?.data?.question;
  const isUserHasQuestion = Boolean(userQuestion);
  const isUserHasFile = Boolean(user?.data?.fileId);

  const userSecondAnswer = user?.data?.second_answer;
  const isUserHasSecondAnswer = Boolean(userSecondAnswer);
  const userSecondQuestion = user?.data?.second_question;
  const isUserHasSecondQuestion = Boolean(userSecondQuestion);
  const isUserNotProvidedAnyInformation = !isUserHasQuestion && !isUserHasFile;
  const isUserProvidedOnlyFile = !isUserHasQuestion && isUserHasFile;

  const handleUserSelect = React.useCallback(
    (user) => {
      setSelectedUserId(user);
    },
    [setSelectedUserId]
  );

  const onAnswerSubmit = React.useCallback(
    async (content, type = 'answer') => {
      setAnswerPending(true);

      try {
        await fetchSendAnswer(selectedUserId, type, content);
        fetchUsers().then((data) => {
          if (selectedUserId) {
            const user = data?.users?.find((user) => user.id === selectedUserId);
            setUser(user);
          }

          const users = Object.entries(data.users).map(([key, value]) => ({
            id: key,
            ...value
          }));
          setUsers(users);
        });
      } catch (error) {
        console.error(error);
      } finally {
        setAnswerPending(false);
      }
    },
    [setAnswerPending, selectedUserId]
  );

  const handleFetchUsers = React.useCallback(async () => {
    setLoading(true);

    await fetchUsers()
      .then((data) => {
        const users = Object.entries(data.users).map(([key, value]) => ({
          id: key,
          ...value
        }));
        setUsers(users);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setLoading, setUsers]);

  React.useEffect(() => {
    handleFetchUsers();
  }, [handleFetchUsers]);

  React.useEffect(() => {
    if (!selectedUserId) {
      setUser(null);
    }

    const user = users?.find((user) => user.id === selectedUserId);
    if (user) {
      setUser(user);
    }
  }, [users, selectedUserId]);

  return (
    <Grid
      container
      sx={{
        background: 'rgb(240, 240, 255)',
        boxShadow: '0 0 35px 35px rgb(240, 240, 255)',
        pb: 10
      }}>
      {!loading ? null : <div>Loading...</div>}

      {!users ? null : (
        <UsersTable
          users={users}
          handleUserSelect={handleUserSelect}
          selectedUserId={selectedUserId}
          onDelete={handleFetchUsers}
        />
      )}

      {!user ? null : (
        <Grid
          container
          sx={{
            mt: 4,
            '& > .MuiGrid-item': {
              mb: 4
            }
          }}>
          {!isUserNotProvidedAnyInformation ? null : (
            <Grid item xs={12}>
              <Typography variant="h5" align="center">
                This user has not yet provided any information for analytics
              </Typography>
            </Grid>
          )}

          {!isUserProvidedOnlyFile ? null : (
            <Grid item xs={12}>
              <Typography variant="h5" align="center">
                This user has only provided a demo file at the moment
              </Typography>
            </Grid>
          )}

          {!isUserHasQuestion ? null : (
            <>
              <Grid item xs={12}>
                <Typography variant="h5">Main problem</Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography sx={{ mt: 2, mb: 2, whiteSpace: 'pre-line', textAlign: 'justify' }}>
                  <WithLinks>{userQuestion}</WithLinks>
                </Typography>
              </Grid>

              <MessageForm
                onSubmit={onAnswerSubmit}
                pending={answerPending}
                disabled={isUserAnswered}
                buttonText={isUserAnswered ? 'Sent' : 'Send'}
                placeholder="Write demo analysis here and push Send"
                readyContent={isUserAnswered ? userAnswer : undefined}
                maxSymbols={5000}
              />
            </>
          )}

          {!isUserHasSecondQuestion ? null : (
            <>
              <Grid item xs={12}>
                <Typography variant="h5">Questions</Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography sx={{ mt: 2, mb: 2, whiteSpace: 'pre-line', textAlign: 'justify' }}>
                  <WithLinks>{userSecondQuestion}</WithLinks>
                </Typography>
              </Grid>

              <MessageForm
                onSubmit={(content) => onAnswerSubmit(content, 'second_answer')}
                pending={answerPending}
                disabled={isUserHasSecondAnswer}
                buttonText={isUserHasSecondAnswer ? 'Sent' : 'Send'}
                placeholder="Write answers here and push Send."
                readyContent={isUserHasSecondAnswer ? userSecondAnswer : undefined}
                maxSymbols={5000}
              />
            </>
          )}
        </Grid>
      )}
    </Grid>
  );
};
